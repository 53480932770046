import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ProgressBar from '../../components/ProgressBar'
import ProgressInfoButton from '../../components/ProgressInfoButton'
import { getUser } from '../../state/user/selectors'
import { readableC02 } from '../../utils/helpers'
import { getPrizeDraw } from '../../state/prize/selectors'
import { formatDate } from '../../utils/date'
import GoldZoneBlock from '../../components/GoldZoneBlock'
import { getLeaderboard } from '../../state/leaderboard/selectors'

export default function ProgressDropDown() {
    const navigate = useNavigate()
    const { user, badgeTasksList } = useSelector(getUser)
    const { prizeDraw } = useSelector(getPrizeDraw)
    const { leaderboard } = useSelector(getLeaderboard)
    const [t] = useTranslation('global')
    const badgeTask = badgeTasksList ? badgeTasksList[0] : null
    const today = new Date()
    const { endsAt } = prizeDraw
    const daysUntilEndsAt = Math.round(((new Date(String(endsAt)) as any) - (new Date(String(today)) as any)) / (1000 * 60 * 60 * 24))
    const day = daysUntilEndsAt <= 1

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : ''

    const goldZoneOptionLength = leaderboard?.leaderboards?.filter((item) => item?.goldZoneOption !== 'off')
    const leaderboards = leaderboard.leaderboards.find((item) => item.id === leaderboard?.currentLeaderboard)

    return (
        <div className="rounded-b-md">
            <div className="pt-[30px] px-[24.5px]">
                {prizeDraw?.showProgressBar ? (
                    <h1 className="heading-uppercase text-22 text-darken font font-medium">
                        {t('navbar.keep-earning-points')}
                    </h1>
                ) : null}

                <div className="mt-[-2px]">
                    {prizeDraw?.showProgressBar ? (
                        prizeDraw?.pointsTargets === undefined || prizeDraw?.pointsTargets.length === 0 ? null : (
                            <div>
                                <span className="text-12 font-normal text-[#5B5B5B]">
                                    {daysUntilEndsAt !== 0 ? daysUntilEndsAt : t('global.last') } {day ? t('global.day') : t('global.days-left')} {t('navbar.to')}
                                </span>&nbsp;

                                <span onClick={() => navigate('/prize-draw')} className="not-heading-font cursor-pointer text-12 font-semibold underline underline-offset-2 text-rocket">
                                    {t('global.enter-the-prize-draw')}
                                </span>
                            </div>
                        )
                    ) : null}

                    {user?.company?.gamification && prizeDraw?.showProgressBar ? (
                        prizeDraw?.pointsTargets === undefined || prizeDraw?.pointsTargets.length === 0 ? null : (
                            <ProgressBar containerStyles={{ marginTop: 10 }} />
                        )
                    ) : null}

                    {prizeDraw?.showProgressBar ? (
                        <div className="columns-2 gap-4 mt-6">
                            <ProgressInfoButton label={`${t('global.since')} ${formatDate(prizeDraw.startsAt, languageData)}`} dataPoint={`${user?.thisMonthPoints || 0} ${t('global.pts')}`} />
                            <ProgressInfoButton label={`${t('global.logged-since')} ${formatDate(prizeDraw.startsAt, languageData)}`} dataPoint={readableC02(user?.thisMonthCeoKg || 0)} />
                            {goldZoneOptionLength?.length > 1 || leaderboards?.goldZoneOption === 'off' ? <div className="min-h-[71px]" /> : <GoldZoneBlock progressDropDown home />}
                            <ProgressInfoButton label={t('global.lifetime')} dataPoint={`${user?.totalPoints || 0} ${t('global.pts')}`} />
                            <ProgressInfoButton label={t('global.logged-lifetime')} dataPoint={readableC02(Number(user?.totalCeoKg))} />
                            <div className="min-h-[71px]" />
                        </div>
                    ) : null}
                </div>
            </div>

            {badgeTask && user?.company?.gamification ? (
                <div className="bg-pistachio mt-5 p-[24.5px]">
                    <span className="heading-font text-18 font-medium font tracking-tight text-darken">
                        {t('navbar.almost-there')}
                    </span>

                    <div className="mt-2 mb-[24px]">
                        <div className="flex">
                            <img src={badgeTask.disabledIcon} className="w-[46px] h-[46px]" alt="" />

                            <div className="w-full ml-3 text-sm">
                                <p className="not-heading-font font-bold tracking-[-0.02rem] text-black">{badgeTask.title}</p>
                                <p className="tracking-[-0.01rem] text-grey01 text-14">{badgeTask.description}</p>
                            </div>
                        </div>

                        <div className="flex mt-1">
                            <div className="not-heading-font font-bold text-xs text-center w-[46px] text-spinach">{badgeTask.currentProgress}/{badgeTask.requiredCompletionsCount}</div>

                            <div className="rounded-full ml-3 flex-1 h-3" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)' }}>
                                <div className="z-10 relative flex rounded-r-sm rounded-l-xl h-3 bg-raspberry" style={{ width: `${badgeTask.completionPercentage}%` }} />
                                <div className="w-full relative z-0 rounded-full bg-white h-2.5 mt-[-11px]" />
                            </div>
                        </div>
                    </div>

                    <span className="heading-font text-18 font-medium font tracking-tight text-darken">
                        {t('navbar.recent-achievements')}
                    </span>

                    {user.earnedBadges?.slice(0, 2).map((item) => (
                        <div className="flex mt-2 items-center" key={item?.id}>
                            <img src={item?.image} className="w-[46px] h-[46px] object-cover" alt="" />

                            <div className="w-full ml-3">
                                <p className="not-heading-font font-bold tracking-[-0.02rem] text-darken text-14">
                                    {item.title}
                                </p>

                                <p className="tracking-[-0.01rem] text-grey01 font-normal text-14">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : <div />}
        </div>
    )
}
