import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Cross, MinusCounter, PlusCounter } from '../common/Svg'
import { getWhiteLabelingList } from '../../state/user/selectors'
import { ITrackTravelCarType, ITransportData, ITransportFuelTypes, ITransportSizes } from '../../types/data'
import { checkIsCar } from '../../utils/travel'
import Button from '../common/Button'
import { travelSelector } from '../../state/travel/selectors'
import { travelDate } from '../../utils/date'

interface IProps {
    onClose?: () => void | undefined;
    setSizeTypeData: (value: any) => void
    setFuelTypeData: (value: any) => void
    sizeTypeData?: ITransportSizes[]
    fuelTypeData?: ITransportFuelTypes[]
    transportName?: string
    countOfPeople: number
    setCountOfPeople: (value: number) => void
    onSave: () => void
    selectedTransports: {[key: string]: ITransportData}
}

export default function MoreDetails({ onClose, selectedTransports, countOfPeople, setCountOfPeople, setSizeTypeData, setFuelTypeData, sizeTypeData, fuelTypeData, transportName, onSave }: IProps) {
    const [t] = useTranslation('global')
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { loading } = useSelector(travelSelector)
    const today = new Date()

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const minDate = outboundData.date.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a))

    const onChangeValueSizeType = (item: ITrackTravelCarType) => {
        const newData = sizeTypeData?.map((newItem) => (newItem.id === item.id
            ? { ...newItem, selected: !newItem.selected }
            : { ...newItem, selected: false }))
        setSizeTypeData(newData)
    }

    const onChangeValueFuelType = (item: ITrackTravelCarType) => {
        const newData = fuelTypeData?.map((newItem) => (newItem.id === item.id
            ? { ...newItem, selected: !newItem.selected }
            : { ...newItem, selected: false }))
        setFuelTypeData(newData)
    }

    const isDisabled = !fuelTypeData?.filter((i) => i.selected).length || !sizeTypeData?.filter((i) => i.selected).length

    return (
        <div>
            {(transportName && checkIsCar(transportName)) || Object.values(selectedTransports).filter((i) => checkIsCar(i?.name)).length ? (
                <div>
                    <h1 className="text-14 font-normal not-heading-font tracking-tight text-cavolo mt-4">
                        {t(`log.${travelDate(today) <= minDate ? 'FUTURE|' : ''}what-size-was-the-vehicle?`)}
                    </h1>

                    <div className="flex flex-wrap mt-2">
                        {sizeTypeData?.map((item) => (
                            <button style={{ opacity: 1 }} type="button" key={item?.id} onClick={() => onChangeValueSizeType(item)}
                                className={`border-2 ${item?.selected ? 'border-cavolo' : 'border-spinach'} h-[40px] rounded-[30px] flex items-center justify-center cursor-pointer mt-[8px] mb-[4px] mr-2 ${item?.selected ? 'px-3 bg-cavolo' : 'px-5 bg-pistachio'}`}>

                                <span className={`text-14 font-semibold not-heading-font ${item?.selected ? 'text-white' : 'text-cavolo'} hover:font-bold hover:ease-in hover:duration-200`}>
                                    {item.name}
                                </span>

                                {item.selected && (
                                    <div className="ml-1.5">
                                        <Cross color={whiteLabeling?.white} width={10} height={10} />
                                    </div>
                                )}
                            </button>
                        ))}
                    </div>

                    <h1 className="text-14 font-normal not-heading-font tracking-tight text-cavolo mt-4">
                        {t(`log.${travelDate(today) <= minDate ? 'FUTURE|' : ''}what-type-of-fuel-did-the-vehicle-use?`)}
                    </h1>

                    <div className="flex flex-wrap mt-2">
                        {fuelTypeData?.map((item) => (
                            <button style={{ opacity: 1 }} type="button" key={item?.id} onClick={() => onChangeValueFuelType(item)}
                                className={`border-2 ${item?.selected ? 'border-cavolo' : 'border-spinach'} h-[40px] rounded-[30px] flex items-center justify-center cursor-pointer mt-[8px] mb-[4px] mr-2 ${item?.selected ? 'px-3 bg-cavolo' : 'px-5 bg-pistachio'}`}>

                                <span className={`text-14 font-semibold not-heading-font ${item?.selected ? 'text-white' : 'text-cavolo'} hover:font-bold hover:ease-in hover:duration-200`}>
                                    {item.name}
                                </span>

                                {item.selected && (
                                    <div className="ml-1.5">
                                        <Cross color={whiteLabeling?.white} width={10} height={10} />
                                    </div>
                                )}
                            </button>
                        ))}
                    </div>

                    <h1 className="text-14 font-normal not-heading-font tracking-tight text-cavolo mt-4">
                        {t(`log.${travelDate(today) <= minDate ? 'FUTURE|' : ''}how-many-other-people-were-in-the-vehicle-with-you?`)}
                    </h1>

                    <div className="flex items-center justify-center mb-6 mt-2.5">
                        <button onClick={() => countOfPeople > 0 && setCountOfPeople(countOfPeople - 1)} className="flex items-center justify-center w-[28px] h-[28px] bg-spinach rounded-full cursor-pointer">
                            <MinusCounter />
                        </button>

                        <div className="flex items-center justify-center w-[146px] h-[48px] border-2 border-spinach rounded-lg mx-[15px]">
                            <h1 className="text-14 font-normal text-black tracking-tight">
                                {countOfPeople}
                            </h1>
                        </div>

                        <button onClick={() => setCountOfPeople(countOfPeople + 1)} className="flex items-center justify-center w-[28px] h-[28px] bg-spinach rounded-full cursor-pointer">
                            <PlusCounter />
                        </button>
                    </div>
                </div>
            ) : (
                <h1 className="h-[280px] text-14 font-normal not-heading-font tracking-tight text-cavolo mt-4">
                    {t('log.no-details-available-for-this-mode-of-transport')}
                </h1>
            )}

            <div className="flex items-center justify-between">
                <button
                    onClick={onClose}
                    className="w-[48%] h-[48px] border border-grey01 rounded-md font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] text-14 text-grey01">
                    {t('global.cancel')}
                </button>

                <Button
                    disabled={isDisabled}
                    text={t('global.save')}
                    onClick={onSave}
                    loading={loading}
                    defaultStyles={false}
                    className={cn('w-[48%] h-[48px] rounded-md font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] text-14 text-white bg-rocket', { 'opacity-50': isDisabled })} />
            </div>
        </div>
    )
}
