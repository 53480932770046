import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import AccountSidebar from '../components/account/AccountSidebar'
import { MoreButton, PlusIconSaved } from '../components/common/Svg'
import SavedPlaceOverlay from '../components/SavedPlaceOverlay'
import { fetchSavedPlaces } from '../state/user/actions'
import { getSavedPlacesList } from '../state/user/selectors'
import { ISavedPlaces } from '../types/data'

export default function SavedPlaces() {
    const dispatch = useDispatch()
    const [t] = useTranslation('global')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [enteredViaMoreButton, setEnteredViaMoreButton] = useState<boolean>(false)
    const { savedPlaces } = useSelector(getSavedPlacesList)
    const [editItem, setEditItem] = useState<ISavedPlaces>()

    useEffect(() => {
        dispatch(fetchSavedPlaces)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Saved Places' })
    }, [])

    const handleMoreButtonClick = (item: ISavedPlaces) => {
        setEditItem(item)
        setEnteredViaMoreButton(true)
        setShowModal(true)
    }

    const handleAddButtonClick = () => {
        setEnteredViaMoreButton(false)
        setShowModal(true)
    }

    return (
        <AccountSidebar>
            <div className="mobile:mt-[52px] mt-[32px] flex-1 tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10 mobile:w-[562px]">
                <h1 className="heading-uppercase text-black font tracking-tight font-medium tablet:text-36 mobile:text-26 text-24">
                    {t('savedPlaces.saved-places')}
                </h1>

                <h1 className="text-16 tracking-tight font-normal text-cavolo mt-5">
                    {t('savedPlaces.saved-places-description')}
                </h1>

                {savedPlaces?.map((item) => (
                    <div key={item?.id} className="flex items-center justify-between mt-4 border-b border-b-grey01">
                        <div className="flex flex-col pb-0.5">
                            <span className="font-bold text-16 text-cavolo tracking-tight">
                                {item?.name}
                            </span>

                            <span className="text-14 font-normal text-grey01 line-clamp-1">
                                {item?.placeName}
                            </span>
                        </div>

                        <div onClick={() => handleMoreButtonClick(item)} className="cursor-pointer">
                            <MoreButton />
                        </div>
                    </div>
                ))}

                <button onClick={handleAddButtonClick} className="flex items-center justify-center mt-5">
                    <div>
                        <PlusIconSaved />
                    </div>

                    <h1 className="ml-1.5 text-16 tracking-tight font-normal text-cavolo">
                        {t('savedPlaces.add-a-saved-place')}
                    </h1>
                </button>

                <SavedPlaceOverlay
                    showModal={showModal}
                    setShowModal={setShowModal}
                    enteredViaMoreButton={enteredViaMoreButton}
                    editItem={editItem}
                    setEditItem={setEditItem} />
            </div>
        </AccountSidebar>

    )
}
