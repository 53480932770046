import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import AccountSidebar from '../../components/account/AccountSidebar'
import { LeftArrow } from '../../components/common/Svg'
import SecondButton from '../../components/common/SecondButton'
import { deleteAccount } from '../../state/user/actions'

export default function DeleteAccount() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Delete account' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    const deleteMyAccount = async () => {
        setLoading(true)
        await dispatch(deleteAccount())
        await navigate('/auth')
        await localStorage.clear()
        setLoading(true)
    }

    return (
        <AccountSidebar>
            <div className="mobile:mt-[52px] mt-[32px] flex-1 tablet:mb-[310px] mb-[80px] tablet:h-screen mobile:ml-10 mx-4 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[760px]">
                <div className="flex items-center">
                    <span onClick={() => navigate('/account-overview')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('account.account')}
                    </span>

                    <div className="mx-[4px]">
                        <LeftArrow height={12} width={12} />
                    </div>

                    <span onClick={() => navigate('/me/account-settings')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('account.My Info')}
                    </span>
                </div>

                <h1 className="mobile:text-36 text-26 font tracking-tight font-medium text-black mt-[5px]">
                    {t('account.delete-your-account')}
                </h1>

                <h1 className="text-14 text-black font-normal mt-[29px]">
                    {t('account.are-you-sure-you-want-to-delete-your-account')}
                </h1>

                <div className="flex flex-col">
                    <SecondButton
                        onClick={() => navigate('/me/account-settings')}
                        text={t('account.no-keep-my-account')}
                        className="mt-[23px] xs-mobile:w-[343px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                    />

                    <SecondButton
                        loading={loading}
                        onClick={deleteMyAccount}
                        className="mt-[24px] bg-mint h-[48px] xs-mobile:w-[343px] font-semibold border rounded-md text-14 text-rocket border-2 border-[#417339] hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D]"
                        text={t('account.yes-delete')}
                    />
                </div>
            </div>
        </AccountSidebar>
    )
}
