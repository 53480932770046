import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { ArrowTravel, DismissIcon } from '../../components/common/Svg'
import Button from '../../components/common/Button'
import { getDates, getDatesLocalization, travelDate } from '../../utils/date'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import Loader from '../../components/common/Loader'
import { ILeg } from '../../types/data'
import { multipleSaveLegs } from '../../state/travel/actions'
import { Context } from '../../components/common/BaseContext'
import Layout from '../../components/layout/Layout'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import { legTransportList } from '../../state/travel/selectors'
import { legInstancesToFormData } from '../../utils/travel'
import { getUser } from '../../state/user/selectors'

export default function TrackTravelReturn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { commuteStartEnd } = useSelector(legTransportList)
    const { user } = useSelector(getUser)
    const today = new Date()
    const { isEmbedded } = useContext(Context)
    const [t] = useTranslation('global')
    const [loading, setLoading] = useState<boolean>(false)
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const date = location?.state?.date
    const gameDate = location?.state?.gameDate
    const postCode = location?.state?.postCode
    const gameId = location?.state?.gameId

    const returnTravelDataString = localStorage.getItem('returnTravelData')
    // @ts-ignore
    const returnTravelData = JSON.parse(returnTravelDataString)
    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const minDate = outboundData.date.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a))

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel return' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onSubmit = async () => {
        setLoading(true)

        const multipleSaveLegsData = outboundData?.legs.sort((a: ILeg, b: ILeg) => b.position - a.position)?.map((item: ILeg, index: number) => ({
            leg: item.id,
            transport: item?.transportId,
            transportSize: item?.transportSize?.id,
            transportFuelType: item?.transportFuelType?.id,
            countOfPeople: item?.countOfPeople,
            isGoingBack: true,
            startPlaceId: item.endPlaceId,
            startPlaceName: item.endPlaceName,
            startPlaceLon: item.endPlaceLon,
            startPlaceLat: item.endPlaceLat,
            endPlaceId: item.startPlaceId,
            endPlaceName: item.startPlaceName,
            endPlaceLon: item.startPlaceLon,
            endPlaceLat: item.startPlaceLat,
            position: index + 1,
        })) || []

        dispatch(multipleSaveLegs({ commute: outboundData?.backendCommuteId || commuteStartEnd.id, legs: multipleSaveLegsData }, async (response) => {
            const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')

            const newLegs = legInstancesToFormData(response.legs)

            localStorage.setItem('returnTravelData', JSON.stringify({
                ...returnTravelData,
                legs: newLegs,
                endPlaceId: travelData.startPlaceId,
                endPlaceName: travelData.startPlaceName,
                endPlaceLon: travelData.startPlaceLon,
                endPlaceLat: travelData.startPlaceLat,
                startPlaceId: travelData.endPlaceId,
                startPlaceName: travelData.endPlaceName,
                startPlaceLon: travelData.endPlaceLon,
                startPlaceLat: travelData.endPlaceLat,
            }))

            setLoading(false)
            navigate('/log/track-travel-overview', { state: { oneWay: false, gameDate, date, postCode, lastPage: location?.pathname, gameId } })
        }))
    }

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const removeReturnJourneyLegs = () => {
        localStorage.setItem('returnTravelData', JSON.stringify({ ...returnTravelData, legs: [] }))
    }

    useEffect(() => {
        const fetchData = async () => {
            if (outboundData?.date || outboundData?.gameDate) {
                const formatted = await getDatesLocalization(outboundData?.date || outboundData?.gameDate, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [outboundData?.date, outboundData?.gameDate])

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded}>
            <div className="w-full h-screen">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                {loading ? (
                    <div className="flex flex-col items-center justify-center h-screen">
                        <h1 className="not-heading-font text-22 tracking-tight font-bold text-black mb-5">
                            {t('log.calculating-your-impact')}
                        </h1>

                        <Loader width={75} height={75} />
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center pt-[30px] pr-2 mobile:mx-[42px] mx-[16px]">
                            <div onClick={() => navigate('/log/track-travel-overview', { state: { gameDate, date, postCode } })} className="flex justify-end cursor-pointer">
                                <ArrowTravel width={22} height={22} />
                            </div>

                            <div onClick={() => setShowQuizQuitOverlayModal(true)} className="flex justify-end cursor-pointer">
                                <DismissIcon width={18} height={18} />
                            </div>
                        </div>

                        <div className="flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                            <div className="max-w-[600px] w-full">
                                <h1 className="heading-uppercase text-cavolo mt-2 text-22 tracking-tight font-semibold">
                                    {t('log.return-journey')}
                                </h1>

                                <h1 className="tracking-tight text-14 not-heading-font text-grey01">
                                    {!outboundData?.gameDate ? formattedDate : getDates(outboundData?.gameDate)}
                                    {postCode?.length > 0 && `, ${postCode}`}
                                </h1>

                                <h1 className="text-cavolo text-18 tracking-tight font-medium not-heading-font mt-4">
                                    {t(`log.${travelDate(today) <= minDate ? 'FUTURE|' : ''}did-you-make-the-same-journey-on-your-way-back?`)}
                                </h1>

                                <Button
                                    className="bg-rocket not-heading-font"
                                    onClick={onSubmit}
                                    text={t(`log.${travelDate(today) <= minDate ? 'FUTURE|' : ''}yes,-i-did`)} />

                                <Button onClick={() => {
                                    removeReturnJourneyLegs()
                                    navigate('/log/track-travel-modality', { state: { date, postCode, gameDate, goingBackCommute: true } })
                                }} type="button" className="bottom-8 bg-pistachio not-heading-font duration-100 border-spinach text-cavolo" text={t(`log.${travelDate(today) <= minDate ? 'FUTURE|' : ''}no,-i-didn’t`)} />

                                <p onClick={() => {
                                    removeReturnJourneyLegs()
                                    navigate('/log/track-travel-overview', { state: { gameDate, date, postCode, lastPage: location?.pathname, gameId, oneWay: true } })
                                }} className="mt-5 text-14 text-center text-cavolo underline font-semibold not-heading-font cursor-pointer">
                                    {t(`log.${travelDate(today) <= minDate ? 'FUTURE|' : ''}this-was-a-one-way-journey`)}
                                </p>
                            </div>
                        </div>
                    </>
                )}

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => (isEmbedded ? navigate('/') : navigate(location?.state.prevUrl || '/'))}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />
            </div>
        </Layout>
    )
}
