import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { IArticles } from '../../types/data'
import { getDateTime } from '../../utils/date'
import Skeleton from '../common/Skeleton'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IArticles
}

export default function DiscoverCard({ item }: IProps) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [t] = useTranslation('global')

    const handleImageLoad = () => setLoading(false)

    let extractedNumber: number | null = null

    if (typeof item?.getReadTime === 'string') {
        const matchResult = item?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    return (
        <div className="cursor-pointer w-full" onClick={() => navigate(`/learn/articles/${item.id}`)} style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            {loading && <Skeleton className="rounded-t-lg mobile:h-[363px] h-auto w-full object-cover" />}
            <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg mobile:h-[363px] h-auto w-full object-cover`} src={item?.image} alt="" />

            <div className="card">
                <div className="pb-3 rounded-b-lg bg-pistachio">
                    {item?.sponsorshipBlock && <SponsoredByBlock noBorder noBackground item={item.sponsorshipBlock} />}

                    <div className={cn('mobile:px-5 px-4', { 'pt-4 mobile:pt-5': !item.sponsorshipBlock, 'mobile:pt-2 pt-0': item.sponsorshipBlock })}>
                        <p className="tracking-[-0.01em] leading-[150%] text-grey01 text-12">
                            {loading ? (
                                <Skeleton className="w-3/12 h-[8px] rounded-sm" />
                            ) : (
                                <>
                                    {getDateTime(item?.createdAt)} &nbsp;|&nbsp; {extractedNumber} {t('global.min')} {t('global.read')}
                                </>
                            )}

                        </p>

                        <button className="flex mt-0.5 font-medium font tracking-tight text-black text-18">
                            {loading ? (
                                <Skeleton className="w-9/12 h-[13px] rounded-sm mt-1" />
                            ) : (
                                <ContentBlockTitle text={item?.title} />
                            )}
                        </button>

                        <p className="flex mt-0.5 font-light tracking-tight text-grey01 text-14">
                            {loading ? (
                                <Skeleton className="w-full mr-[55%] h-[13px] rounded-sm mt-1" />
                            ) : (
                                item?.subTitle
                            )}
                        </p>
                    </div>

                    {/* <div className={cn({ hidden: !item?.sponsoredBy })}> */}
                    {/*    <div className="mobile:mt-5 mt-4 mb-3 bg-lime h-1" /> */}

                    {/*    <div className="flex items-center justify-between mobile:px-5 px-4"> */}
                    {/*        <span className="font-light tracking-tight text-grey01 text-13"> */}
                    {/*            {t('global.in-partnership-with')} */}
                    {/*        </span> */}

                    {/*        <span> */}
                    {/*            {item?.sponsoredBy} */}
                    {/*        </span> */}
                    {/*    </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}
