import React, { useRef } from 'react'
import { Field } from 'formik'
import { FieldProps } from 'formik/dist/Field'
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2'
import { useSelector } from 'react-redux'
import 'react-phone-input-2/lib/style.css'
import { Colors } from '../../themes'
import ValidationErrorMessage from './ValidationErrorMessage'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    name: string
    label?: string
    component?: string
    size?: number
    placeholder?: string
    type?: string
    validate?: any
    className?: string
    error?: string | {
        nonFieldErrors: string[]
        detail: string[]
    }
    defaultValue?: string
    onCountryChange?: (countryCode: string) => void
    setCountryCode?: (value: string) => void
    countryCode?: string
}

// eslint-disable-next-line react/function-component-definition
const CustomPhoneInput: React.FC<IProps> = ({ label, defaultValue, countryCode, setCountryCode, onCountryChange, name, validate, ...attributes }: IProps) => {
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    const phoneInputRef = useRef<PhoneInputProps>(null)

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            const { form } = event.currentTarget
            if (form) {
                const index = Array.prototype.indexOf.call(form, event.currentTarget)
                if (form.elements[index + 1]) {
                    (form.elements[index + 1] as HTMLElement).focus()
                }
            }
        }
    }

    const handleOnChange = (value: string, data: CountryData) => {
        if (setCountryCode) {
            setCountryCode(data.countryCode)
        }
        if (onCountryChange) {
            onCountryChange(data.countryCode)
        }
    }

    return (
        <>
            {label && (
                <label style={styles.label} htmlFor={name} className="block font-medium font">
                    {label}
                </label>
            )}

            <Field name={name} validate={validate}>
                {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                    <PhoneInput
                        // @ts-ignore
                        ref={phoneInputRef}
                        country={countryCode || (loginConfig?.defaultCountry || 'gb')}
                        buttonStyle={{
                            borderColor: loginConfig?.whiteLabeling?.avocado || whiteLabeling?.avocado,
                            background: '#fff',
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                        }}
                        inputStyle={{
                            borderColor: loginConfig?.whiteLabeling?.avocado || whiteLabeling?.avocado,
                            width: '100%',
                            height: 50,
                            borderRadius: 8,
                            color: loginConfig?.whiteLabeling?.black || whiteLabeling?.black,
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: '24px',
                        }}
                        inputProps={{
                            onKeyDown: handleKeyDown,
                        }}
                        value={value || defaultValue}
                        onChange={(phone: string, data: CountryData) => {
                            setFieldValue(name, phone)
                            handleOnChange(phone, data)
                        }}
                        {...attributes} />
                )}
            </Field>

            <ValidationErrorMessage name={name} />
        </>
    )
}

const styles = {
    label: {
        color: Colors.black,
        fontSize: 14,
        marginBottom: 6,
    },
}

export default CustomPhoneInput
