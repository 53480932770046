import React, { useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import GoogleAutoComplete from '../../components/GoogleAutoComplete'
import Button from '../../components/common/Button'
import { Colors } from '../../themes'
import { getWhiteLabelingList } from '../../state/user/selectors'
import { commuteStartEndList, travelSelector } from '../../state/travel/selectors'
import { fetchLastMultiDaySection } from '../../state/travel/actions'
import { travelDate } from '../../utils/date'

export default function LastMultiDaySection() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const today = new Date()
    const { isEmbedded } = useContext(Context)
    const location = useLocation()
    const [t] = useTranslation('global')
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { travelAccommodation } = useSelector(travelSelector)

    const buttonRef = useRef<any>()
    const endRef = useRef<any>()

    const [accommodationPlace, setAccommodationPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const { commuteStartEnd, loading } = useSelector(commuteStartEndList)

    const lastItem = travelAccommodation.multiDaySections[travelAccommodation.multiDaySections.length - 1]
    const minDate = outboundData.date.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a))

    const onSubmit = () => {
        dispatch(fetchLastMultiDaySection({
            commute: outboundData.backendCommuteId || commuteStartEnd.id,
            endPlaceId: accommodationPlace.placeId,
            endPlaceName: accommodationPlace.name,
            endPlaceLon: accommodationPlace.lon,
            endPlaceLat: accommodationPlace.lat,
        }, lastItem.id, () => {
            navigate('/log/track-travel-modality', location)
        }))
    }

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false}>
            <div className="w-full">
                <style>
                    {`
                        .radio-style input[type='radio'] {
                            outline: none;
                            appearance: none;
                            width: 24px;
                            height: 24px;
                            border: 1px solid #417339FF;
                            padding: 3px; /* Control the space between border and background */
                        }

                        .btnd:active,
                        .btnd:focus,
                        .btnd:focus:active {
                            background-image: none;
                            outline: 0;
                            box-shadow: none;
                        }

                        .radio-style input[type="radio"]:checked {
                            width: 24px;
                            height: 24px;
                            border-color: #417339FF;
                            background: ${(whiteLabeling as any)?.rocket} content-box;
                        }
                    `}
                </style>

                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div className="flex flex-col items-center mobile:pt-[54px] pt-5 mobile:px-[42px] px-[16px] h-screen">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="heading-uppercase text-22 font tracking-tight font-semibold text-cavolo mt-2">
                            {t('overnightStay.return-destination')}
                        </h1>

                        <h1 className="font-medium not-heading-font text-cavolo text-16 mt-4 mb-2.5">
                            {travelDate(today) <= minDate ? (
                                t('overnightStay.FUTURE|where-did-you-go-after-leaving-last-location-on-the-final-day-of-your-trip').replace('[last-location]', lastItem?.startPlaceName?.split(',')[0])
                            ) : (
                                t('overnightStay.where-did-you-go-after-leaving-last-location-on-the-final-day-of-your-trip').replace('[last-location]', lastItem?.startPlaceName?.split(',')[0])
                            )}
                        </h1>

                        <GoogleAutoComplete onChange={() => { buttonRef.current.focus() }} selectRef={endRef} placeholder={t('log.start-typing')} defaultValue={accommodationPlace} setPlace={setAccommodationPlace} />
                    </div>
                </div>

                <div
                    className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                    style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                    <div
                        className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                        <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                        <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                            <Button
                                buttonRef={(ref: any) => {
                                    buttonRef.current = ref
                                }}
                                loading={loading}
                                disabled={!accommodationPlace.placeId}
                                onClick={onSubmit}
                                style={!accommodationPlace.placeId ? disabledStyles : undefined}
                                className="absolute bottom-8 duration-100 not-heading-font"
                                text={t('global.continue')} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
