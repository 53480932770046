import React, { useCallback, useContext, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import ReactGA from 'react-ga4'
import { getUser } from '../../state/user/selectors'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import { getHomePageLists } from '../../state/home/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getPrizeDraw } from '../../state/prize/selectors'
import ProgressBar from '../../components/ProgressBar'
import ProgressAccountInfoButton from '../../components/ProgressAccountInfoButton'
import { formatDate } from '../../utils/date'
import { readableC02 } from '../../utils/helpers'
import BadgeTaskItem from '../../components/BadgeTaskItem'
import BadgeItem from '../../components/BadgeItem'
import { getLeaderboard } from '../../state/leaderboard/selectors'
import { fetchLeaderboard } from '../../state/leaderboard/actions'
import Skeleton from '../../components/common/Skeleton'

export default function Progress() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, badgeTasksList } = useSelector(getUser)
    const { leaderboard, loading } = useSelector(getLeaderboard)
    const { navbarHeight } = useContext(Context)
    const { prizeDraw } = useSelector(getPrizeDraw)
    const [t] = useTranslation('global')
    const today = new Date()

    const { endsAt } = prizeDraw
    const daysUntilEndsAt = Math.round(((new Date(String(endsAt)) as any) - (new Date(String(today)) as any)) / (1000 * 60 * 60 * 24))
    const day = daysUntilEndsAt <= 1

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : ''

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchLeaderboard({ page: 1 }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        if (leaderboard.results.length === 0) onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const badgeTask = badgeTasksList ? badgeTasksList[0] : null

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Progress')

    const goldZoneOptionLength = leaderboard?.leaderboards?.filter((item) => item?.goldZoneOption !== 'off')

    const leaderboards = leaderboard.leaderboards.find((item) => item.id === leaderboard?.currentLeaderboard)
    const goldZonePoint = (user?.goldZone?.goldZonePoints || 0) - (user?.goldZone?.currentPoints || 0)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Progress' })
    }, [])

    return (
        <Layout contentContainerClassName="flex-col">
            {sponsoredBlock && <div className="mobile:hidden w-screen left-[-24px]"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div className="w-full flex flex-col items-center">
                <div style={{ top: navbarHeight }} className="w-11/12 medium:w-[50%] laptop:mx-[223px] tablet:mx-[103px] mobile:mx-10 mx-4 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]">
                    {prizeDraw?.showProgressBar ? (
                        prizeDraw?.pointsTargets === undefined || prizeDraw?.pointsTargets.length === 0 ? null : (
                            <h1 className="heading-uppercase hidden medium:block mobile:text-36 text-26 font tracking-tight font-medium text-black">
                                {t('me.progress')}
                            </h1>
                        )
                    ) : null}

                    <div className="items-center flex flex-col max-w-[764px]">
                        <div className="w-full">
                            {prizeDraw?.showProgressBar && prizeDraw?.pointsTargets?.length > 0 && (
                                <>
                                    <div className="font-light mt-2 text-14 tracking-[-0.01rem] leading-[150%]">
                                        <span className="tracking-tight text-grey01">
                                            {daysUntilEndsAt !== 0 ? daysUntilEndsAt : t('global.last')} {day ? t('global.day') : t('global.days')} {t('me.left-to')}
                                        </span>&nbsp;

                                        <span onClick={() => navigate('/prize-draw')} className="not-heading-font cursor-pointer font-semibold underline underline-offset-2 tracking-[-0.02rem] text-rocket">
                                            {t('global.enter-the-prize-draw')}
                                        </span>
                                    </div>
                                    <ProgressBar containerStyles={{ marginTop: window.self.innerWidth <= 767 ? 12 : 16 }} progress />
                                </>
                            )}

                            <div className="relative">
                                {prizeDraw?.showProgressBar && (
                                    <div>
                                        <p className="font text-18 text-black mb-3 mobile:mt-[35px] mt-[28px] leading-[132%] tracking-[-0.02rem] heading-font">
                                            {t('me.your-stats')}
                                        </p>

                                        <div className="columns-2 mobile:gap-4 gap-3 relative">
                                            <ProgressAccountInfoButton
                                                label={`${t('global.since')} ${formatDate(prizeDraw.startsAt, languageData)}`}
                                                dataPoint={`${user?.thisMonthPoints || 0} ${t('global.pts')}`} />
                                            <ProgressAccountInfoButton
                                                label={`${t('global.logged-since')} ${formatDate(prizeDraw.startsAt, languageData)}`}
                                                dataPoint={readableC02(user?.thisMonthCeoKg || 0)} />
                                            {loading ? (
                                                <Skeleton
                                                    className="min-h-[55px] mobile:min-h-[82px] laptop:min-h-[90px] rounded-lg" />
                                            ) : (
                                                goldZoneOptionLength?.length > 1 || leaderboards?.goldZoneOption === 'off' || loading
                                                    ? <div className="min-h-[55px] mobile:min-h-[82px] laptop:min-h-[90px]" />
                                                    : ((isEmpty(goldZoneOptionLength) || goldZoneOptionLength?.length > 1) || leaderboards?.goldZoneOption === 'off' ? null : (
                                                        <div
                                                            className="flex flex-col min-h-[55px] mobile:min-h-[82px] laptop:min-h-[90px] bg-pistachio rounded-lg mobile:p-[16px] py-[8px] px-[12px]">
                                                            <p className="font font-medium not-heading-font w-full tracking-[-0.02rem] text-black text-16 mobile:text-22 laptop:text-26 mobile:leading-[29.04px] leading-[21.12px]">
                                                                {goldZonePoint > 0
                                                                    ? `${goldZonePoint} ${t('global.pts')}`
                                                                    : t('global.well-done')}
                                                            </p>

                                                            <p className="tracking-[-0.01rem] w-full leading-[150%] text-grey01 text-12 mobile:text-14 mobile:leading:[21px] leading:[18px]">
                                                                {goldZonePoint > 0
                                                                    ? leaderboard?.leaderboards.length === 1 ? t('me.to-gold-zone') : t('me.to-reach-org-unit-name-gold-zone').replace('[org-unit-name]', user?.company?.organisationalUnitName.toLowerCase())
                                                                    : t('me.you\'re-in-the-org-unit-name-gold-zone').replace('[org-unit-name]', user?.company?.organisationalUnitName.toLowerCase())}
                                                            </p>
                                                        </div>
                                                    ))
                                            )}
                                            <ProgressAccountInfoButton label={t('global.lifetime')}
                                                dataPoint={`${user?.totalPoints || 0} ${t('global.pts')}`} />
                                            <ProgressAccountInfoButton label={t('global.logged-lifetime')}
                                                dataPoint={readableC02(Number(user?.totalCeoKg))} />
                                            <div className="min-h-[55px] mobile:min-h-[82px] laptop:min-h-[90px]" />
                                        </div>
                                    </div>
                                )}

                                {isEmpty(prizeDraw) || !prizeDraw?.showProgressBar ? null : (
                                    <button
                                        onClick={() => navigate('/me/leaderboard')}
                                        className="heading-font border border-solid border-rocket bg-mint hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D] h-[48px] w-[180px] font-semibold rounded-md mt-1.5 mobile:mt-3 laptop:mt-4 text-14 text-marrow mb-8">
                                        {t('me.view-leaderboard')}
                                    </button>
                                )}

                                {user?.company?.gamification ? (
                                    <div>
                                        <div className="mobile:mt-2 font tracking-[-0.02rem]">
                                            {badgeTask && user?.company?.gamification ? (
                                                <div>
                                                    <p className="font text-18 text-black mb-5 leading-[132%] tracking-[-0.02rem] heading-font">
                                                        {t('me.almost-there')}
                                                    </p>

                                                    <BadgeTaskItem item={badgeTask} />
                                                </div>
                                            ) : <div />}

                                            <p className="font text-18 text-black mt-6 mobile:mt-8 leading-[132%] tracking-[-0.02rem] heading-font">
                                                {t('me.recent-achievements')}
                                            </p>

                                            {user.earnedBadges?.slice(0, 2).map((item) => (
                                                <div key={item?.id}>
                                                    <BadgeItem item={item} />
                                                </div>
                                            ))}
                                        </div>

                                        <button
                                            onClick={() => navigate('/me/achievements')}
                                            className="border border-solid not-heading-font border-rocket px-[30px] bg-mint hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D] h-[48px] font-semibold rounded-md mt-6 mobile:mt-7 laptop:mt-8 text-14 text-marrow mb-[80px] mobile:mb-[120px]">
                                            {t('me.view-all-achievements')}
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
