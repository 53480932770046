import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ArrowBottomIcon, BurgerIcon, CrossIcon, FlagIcon, LeafIcon } from '../common/Svg'
import { getUser } from '../../state/user/selectors'
import { getChallengeList } from '../../state/challenges/selectors'
import { readableC02 } from '../../utils/helpers'
import { getPrizeDraw } from '../../state/prize/selectors'

interface IProps {
    navigation: any
}

function Sidebar({ navigation }: IProps) {
    const navigate = useNavigate()
    const [showSidebar, setShowSidebar] = useState(false)
    const { prizeDraw } = useSelector(getPrizeDraw)
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { challenge } = useSelector(getChallengeList)
    const unLoggedDaysCountIsJoined = challenge.filter((item) => item.isJoined)
    const unLoggedDaysCount = unLoggedDaysCountIsJoined?.map((item) => item.unloggedDaysCount)
        .reduce((a, b) => a + b, 0)

    const navLinkStyle = ({ isActive }: any) => ((
        isActive ? styles.activeText : styles.defaultText
    ))

    return (
        <>
            {showSidebar ? (
                <>
                    <button onClick={() => setShowSidebar(!showSidebar)}>
                        <div className="max-tablet:hidden">
                            <div className="flex items-center justify-end mb-0.5 ml-6 h-[30px] pt-[5px]">
                                <BurgerIcon width={18} height={16} />

                                {unLoggedDaysCount !== 0 && (
                                    <div className="absolute right-[-8px] mb-[18px]">
                                        <div className="flex items-center justify-center w-[12px] h-[12px] bg-grapeFruit rounded-[40px]" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </button>

                    <button style={{ right: 42, top: 37 }} className="z-[100001] fixed" onClick={() => setShowSidebar(!showSidebar)}>
                        <CrossIcon width={16} height={16} />
                    </button>
                </>
            ) : (
                <button onClick={() => setShowSidebar(!showSidebar)}>
                    <div className="medium:hidden">
                        <div className="flex items-center justify-end mb-0.5 ml-6 h-[30px] pt-[5px] relative">
                            <BurgerIcon width={18} height={16} />

                            {unLoggedDaysCount !== 0 && (
                                <div className="absolute right-[-8px] mb-[18px]">
                                    <div className="flex items-center justify-center w-[12px] h-[12px] bg-grapeFruit rounded-[40px]" />
                                </div>
                            )}
                        </div>
                    </div>
                </button>
            )}

            <div className={`bg-rocket z-[100000] w-[294px] pt-[108px] pl-[60px] top-0 right-0 fixed h-full ease-in-out duration-500 ${showSidebar ? 'translate-x-0' : 'translate-x-full'}`}>
                {navigation?.map((item: any) => (
                    <NavLink key={item.name} to={item.href} style={navLinkStyle}>
                        <h3 className="text-white">
                            {t(`navbar.${item.name}`)}
                        </h3>
                    </NavLink>
                ))}

                <div className="border-[0.7px] w-[200px] border-rocket" />

                {user?.company?.gamification ? (
                    prizeDraw?.showProgressBar && (
                        <div onClick={() => navigate('/me/progress')}>
                            <div className="mt-[32px]">
                                <div className="flex items-center">
                                    <div>
                                        <LeafIcon width={16} height={16} />
                                    </div>

                                    <span className="text-15 text-white font-medium ml-1.5 mr-3.5 font">
                                        {user?.totalPoints} pts
                                    </span>
                                </div>
                            </div>

                            <div className="flex items-center mt-[15px]">
                                <div>
                                    <FlagIcon width={16} height={16} />
                                </div>

                                <span className="text-15 text-white font-medium ml-1.5 mr-3.5 font">
                                    #{user?.rank === null ? 'Top' : user?.rank}
                                </span>
                            </div>

                            <div className="flex items-center mt-[12px]">
                                <div className="progress-icon">
                                    <ArrowBottomIcon width={16} height={16} color="#fff" />
                                </div>

                                <span className="text-15 text-white font-medium ml-1.5 mr-4 font">
                                    {readableC02(user?.thisMonthCeoKg || 0)}
                                </span>
                            </div>
                        </div>
                    )
                ) : null}

                {user?.company?.gamification && (
                    <button onClick={() => navigate('/me/progress')} className="mt-6 font-semibold leading-[150%] tracking-[-0.02rem] underline underline-offset-2 text-14 text-white">
                        {t('navbar.view-progress')}
                    </button>
                )}
            </div>
        </>
    )
}

export default Sidebar

const styles = {
    defaultText: {
        display: 'flex',
        height: 59,
        fontSize: 18,
        letterSpacing: 0.45,
        color: '#EEFFED',
    },
    activeText: {
        display: 'flex',
        height: 59,
        fontSize: 18,
        fontWeight: 700,
        letterSpacing: 0.45,
        color: '#EEFFED',
    },
}
