const BASE = 'travel/'

export const FETCH_TRAVEL_START = `${BASE}FETCH_QUIZ_START`

export const FETCH_GAME_LIST_SUCCESS = `${BASE}FETCH_GAME_LIST_SUCCESS`
export const FETCH_TRANSPORT_LIST_SUCCESS = `${BASE}FETCH_TRANSPORT_LIST_SUCCESS`
export const FETCH_RECENT_ROUTES_SUCCESS = `${BASE}FETCH_RECENT_ROUTES_SUCCESS`
export const FETCH_TRANSPORT_CEO2_SUCCESS = `${BASE}FETCH_TRANSPORT_CEO2_SUCCESS`
export const FETCH_FAVOURITE_ROUTES_SUCCESS = `${BASE}FETCH_FAVOURITE_ROUTES_SUCCESS`
export const POST_TRAVEL_COMMUTE_SUCCESS = `${BASE}POST_TRAVEL_COMMUTE_SUCCESS`
export const POST_LOG_ROUTE_SUCCESS = `${BASE}POST_LOG_ROUTE_SUCCESS`
export const FETCH_STATISTICS_FAN_SUCCESS = `${BASE}FETCH_STATISTICS_FAN_SUCCESS`
export const FETCH_STATISTICS_EMPLOYEE_SUCCESS = `${BASE}FETCH_STATISTICS_EMPLOYEE_SUCCESS`
export const DELETE_LEG_SUCCESS = `${BASE}DELETE_LEG_SUCCESS`
export const FETCH_JOURNEY_TYPES_SUCCESS = `${BASE}FETCH_JOURNEY_TYPES_SUCCESS`
export const FETCH_TRANSPORT_MODES_SUCCESS = `${BASE}FETCH_TRANSPORT_MODES_SUCCESS`
export const FETCH_TRAVEL_CONTENT_BLOCK_SUCCESS = `${BASE}FETCH_TRAVEL_CONTENT_BLOCK_SUCCESS`
export const COMMUTE_START_END_SUCCESS = `${BASE}COMMUTE_START_END_SUCCESS`
export const LEG_TRANSPORT_SUCCESS = `${BASE}LEG_TRANSPORT_SUCCESS`
export const CLEAR_LEG_TRANSPORT = `${BASE}CLEAR_LEG_TRANSPORT`
export const CLEAR_COMMUTE_START_END = `${BASE}CLEAR_COMMUTE_START_END`
export const LEG_TRANSPORT_DETAILS_SUCCESS = `${BASE}LEG_TRANSPORT_DETAILS_SUCCESS`
export const LEG_START_END_SUCCESS = `${BASE}LEG_START_END_SUCCESS`
export const MULTIPLE_SAVE_LEGS_SUCCESS = `${BASE}MULTIPLE_SAVE_LEGS_SUCCESS`
export const COMMUTE_SAVE_SUCCESS = `${BASE}COMMUTE_SAVE_SUCCESS`
export const CLEAR_COMMUTE = `${BASE}CLEAR_COMMUTE`
export const REMOVE_OR_OFFSET_SUCCESS = `${BASE}REMOVE_OR_OFFSET_SUCCESS`
export const PLAN_JOURNEY_SUCCESS = `${BASE}PLAN_JOURNEY_SUCCESS`
export const PRIZE_DRAW_ENTRY_SUCCESS = `${BASE}PRIZE_DRAW_ENTRY_SUCCESS`
export const FETCH_TRAVEL_ARTICLES_SUCCESS = `${BASE}FETCH_TRAVEL_ARTICLES_SUCCESS`
export const POST_TRAVEL_ARTICLE_BOOKMARK_SUCCESS = `${BASE}POST_TRAVEL_ARTICLE_BOOKMARK_SUCCESS`
export const POST_ACCOMMODATION_SUCCESS = `${BASE}POST_ACCOMMODATION_SUCCESS`
export const FETCH_TRAVEL_COMMUTE_DETAIL_SUCCESS = `${BASE}FETCH_TRAVEL_COMMUTE_DETAIL_SUCCESS`
export const FETCH_TRAVEL_ERROR = `${BASE}FETCH_QUIZ_ERROR`
