import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import isEmpty from 'lodash/isEmpty'
import { Colors } from '../themes'
import { getPlanJourney } from '../state/travel/actions'
import Layout from '../components/layout/Layout'
import GoogleAutoComplete from '../components/GoogleAutoComplete'
import Button from '../components/common/Button'
import { travelSelector } from '../state/travel/selectors'
import { DefaultHeartIcon } from '../components/common/Svg'
import { getSavedPlacesList, getUser } from '../state/user/selectors'
import { fetchSavedPlaces } from '../state/user/actions'

export default function PlanJourney() {
    const [t] = useTranslation('global')
    const [startPlace, setStartPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [endPlace, setEndPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { savedPlaces } = useSelector(getSavedPlacesList)
    const { user } = useSelector(getUser)
    const { loading } = useSelector(travelSelector)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Plan' })
    }, [])

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    const onSubmit = () => {
        dispatch(getPlanJourney({
            startPlaceId: startPlace.placeId,
            startPlaceName: startPlace.name,
            startPlaceLon: startPlace.lon,
            startPlaceLat: startPlace.lat,
            endPlaceId: endPlace.placeId,
            endPlaceName: endPlace.name,
            endPlaceLon: endPlace.lon,
            endPlaceLat: endPlace.lat,
        }, () => {
            navigate('/plan/plan-journey-result')
        }))
    }

    useEffect(() => {
        dispatch(fetchSavedPlaces)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    const isDisabled = !startPlace.placeId || !endPlace.placeId

    return (
        <Layout>
            <div className="w-full min-h-screen">
                <div className="h-screen pb-[500px] overflow-y-scroll flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            Sustainable journey planner
                        </h1>

                        <div>
                            <div className="flex items-center justify-between mt-4 mb-[10px]">
                                <h1 className="font-medium not-heading-font text-cavolo text-16">
                                    {t('planner.starting-from')}
                                </h1>
                            </div>

                            {isEmpty(savedPlaces) && user?.company?.showSavedPlaces ? (
                                <div className="flex items-center mb-2.5">
                                    <DefaultHeartIcon />

                                    <h1 className="ml-2.5 text-12 text-grey01">
                                        {t('savedPlaces.tap-the-heart-to-save-a-place-for-future-use')}
                                    </h1>
                                </div>
                            ) : null}

                            <GoogleAutoComplete placeholder={t('log.start-typing')} setPlace={setStartPlace} defaultValue={startPlace} />
                        </div>

                        <div className="mt-[28px]">
                            <div className="flex items-center justify-between mt-4 mb-[10px]">
                                <h1 className="font-medium not-heading-font text-cavolo text-16">
                                    {t('planner.going-to')}
                                </h1>
                            </div>

                            <GoogleAutoComplete placeholder={t('log.start-typing')} defaultValue={endPlace} setPlace={setEndPlace} />
                        </div>

                        <Button loading={loading} disabled={isDisabled} onClick={onSubmit} text={t('planner.go')} style={{ marginTop: 30, ...(isDisabled ? disabledStyles : {}) }} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}
