import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Radio } from '../common/Radio'
import { ILeg } from '../../types/data'
import { TravelTreeSmallEllipse } from '../common/Svg'
import { getUser } from '../../state/user/selectors'

interface IProps {
    isShown?: boolean;
    onClose?: () => void;
    goingBackCommute: boolean
    multiDaySection?: any
}

const AddLegModalData = (t: any) => [
    {
        id: 1,
        name: t('log.starting-at'),
    },
    {
        id: 2,
        name: t('log.ending-at'),
    },
]

export default function AddLegModal({ isShown = false, onClose, goingBackCommute, multiDaySection }: IProps) {
    const [t] = useTranslation('global')
    const data = AddLegModalData(t)
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [selectedValue, setSelectedValue] = useState<string | number | undefined>(1)

    const storedDataString = localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData')
    // @ts-ignore
    const storedData = JSON.parse(storedDataString)

    const { legs } = multiDaySection || storedData

    const uniquePlaces = legs.sort((a: any, b: any) => a.position - b.position).reduce((accumulator: any, currentLeg: any) => {
        const startPlace = currentLeg.startPlaceName
        const endPlace = currentLeg.endPlaceName

        if (!accumulator.includes(startPlace)) {
            accumulator.push(startPlace)
        }
        if (!accumulator.includes(endPlace)) {
            accumulator.push(endPlace)
        }

        return accumulator
    }, [])

    if (selectedValue === 1 && !uniquePlaces.includes(t('log.new-overall-start-location')) && !['event', 'accommodation'].includes(multiDaySection?.start)) {
        uniquePlaces.unshift(t('log.new-overall-start-location'))
    }

    if (selectedValue === 2 && user?.company?.travelTrackingType !== 'event' && !uniquePlaces.includes(t('log.new-overall-end-location')) && !['event', 'accommodation'].includes(multiDaySection?.start)) {
        uniquePlaces.push(t('log.new-overall-end-location'))
    }

    const placesInfo: any = {}

    legs.map((i: ILeg) => {
        placesInfo[String(i?.startPlaceName)] = {
            placeId: i.startPlaceId,
            name: i.startPlaceName,
            lat: i.startPlaceLat,
            lon: i.startPlaceLon,

            createLegPosition: i.position,
            leg: i,
            start: true,
        }

        placesInfo[String(i?.endPlaceName)] = {
            placeId: i.endPlaceId,
            name: i.endPlaceName,
            lat: i.endPlaceLat,
            lon: i.endPlaceLon,

            createLegPosition: i.position + 1,
            leg: i,
            end: true,
        }

        return i
    })

    const handleClick = (place: string) => {
        let isOverallStart = false
        let isOverallEnd = false
        const placeInfo = placesInfo[place]

        if (place === '(new overall start location)') {
            isOverallStart = true
        }

        if (place === '(new overall end location)' || (placeInfo?.placeId === storedData.endPlaceId && selectedValue === 1 && !user.company.isTravelTypeEvent)) {
            isOverallEnd = true
        }

        if (isOverallStart || isOverallEnd) {
            // const firstLeg = storedData.legs[0]

            localStorage.setItem(goingBackCommute ? 'returnTravelData' : 'travelData', JSON.stringify({
                ...storedData,
                createLegPosition: isOverallStart ? 1 : (storedData?.legs?.length || 0) + 1,
                isOverallStart,
                isOverallEnd,
                legStartEnd: {
                    startPlaceName: isOverallEnd ? storedData.endPlaceName : '',
                    startPlaceId: isOverallEnd ? storedData.endPlaceId : '',
                    startPlaceLon: isOverallEnd ? storedData.endPlaceLon : '',
                    startPlaceLat: isOverallEnd ? storedData.endPlaceLat : '',

                    endPlaceName: isOverallStart ? storedData.startPlaceName : '',
                    endPlaceId: isOverallStart ? storedData.startPlaceId : '',
                    endPlaceLon: isOverallStart ? storedData.startPlaceLon : '',
                    endPlaceLat: isOverallStart ? storedData.startPlaceLat : '',
                },
                legStartEndEmpty: false,
                createStartLeg: false,
                createEndLeg: false,
            }))
            navigate('/log/track-travel-modality', { state: { goingBackCommute } })
            return
        }

        localStorage.setItem(goingBackCommute ? 'returnTravelData' : 'travelData', JSON.stringify({
            ...storedData,
            createLegPosition: placeInfo.createLegPosition,
            createLegMultiDaySection: multiDaySection,
            legStartEnd: {
                startPlaceName: selectedValue === 1 ? placeInfo.name : undefined,
                startPlaceId: selectedValue === 1 ? placeInfo.placeId : undefined,
                startPlaceLon: selectedValue === 1 ? placeInfo.lon : undefined,
                startPlaceLat: selectedValue === 1 ? placeInfo.lat : undefined,

                endPlaceName: selectedValue === 2 ? placeInfo.name : undefined,
                endPlaceId: selectedValue === 2 ? placeInfo.placeId : undefined,
                endPlaceLon: selectedValue === 2 ? placeInfo.lon : undefined,
                endPlaceLat: selectedValue === 2 ? placeInfo.lat : undefined,
            },
            isOverallStart: false,
            isOverallEnd: false,
            createStartLeg: selectedValue === 1,
            createEndLeg: selectedValue === 2,
        }))

        if ((placeInfo.start && selectedValue === 1) || (placeInfo.end && selectedValue === 2)) {
            localStorage.setItem('travelDataEdit', JSON.stringify({ ...placeInfo.leg, hideSelectedValues: true }))
            navigate('/log/track-travel-modality', { state: { edit: true, goingBackCommute } }) // : navigate('/track-travel-leg-start-end', { state: { impact, gameDate, gameId, date: new Date(date), fuel: item.transportFuelType, postCode, sizeCar: item.transportSize, choosedCar: item.choosedCar, edit: true, goingBackCommute, distanceNumberBack } })
        } else {
            navigate('/log/track-travel-modality', { state: { goingBackCommute } })
        }
    }

    const handleRadioChange = (value: string | number) => {
        setSelectedValue(value)
    }

    const handleClickInsideModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
    }

    useEffect(() => {
        const handleGlobalClick = () => {
            onClose?.()
        }

        document.addEventListener('mousedown', handleGlobalClick)
        return () => {
            document.removeEventListener('mousedown', handleGlobalClick)
        }
    }, [onClose])

    const renderPlaces = (places: string[]) => places.map((place: string, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="flex items-center ml-[35px] mb-4 cursor-pointer" onClick={() => handleClick(place)}>
            <TravelTreeSmallEllipse />

            <h1 className="ml-[17px] tex-14 font-normal text-cavolo tracking-[-0.02rem]">
                {place}
            </h1>
        </div>
    ))

    return (
        <div>
            {isShown && (
                <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                    <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                    <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                        <div className="bg-white relative px-4 py-6 xs-mobile:w-[500px] w-11/12 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }} onMouseDown={handleClickInsideModal}>
                            <h1 className="heading-uppercase text-18 text-cavolo font-semibold tracking-[-0.02rem]">
                                {t('log.add-leg')}
                            </h1>

                            <div className="tablet:mt-[16px] mt-[10px] cursor-pointer">
                                {data?.map((item) => (
                                    <Radio
                                        inputClassName="mb-4 tablet:mb-[20px]"
                                        key={item.id}
                                        label={item?.name}
                                        // @ts-ignore
                                        value={item?.id}
                                        labelClassName="text-black tablet:text-16 text-14 mb-4 tablet:mb-[20px]"
                                        checked={Number(selectedValue) === item.id}
                                        onChange={handleRadioChange}
                                    />
                                ))}
                            </div>

                            {renderPlaces(uniquePlaces)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
