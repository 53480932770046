import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import logoWhite from '../../static/lowr-white.png'
import { ArrowBottomIcon,
    FlagIcon,
    LeafIcon,
    LearnIcon,
    LowerIcon,
    MeIcon,
    SettingsIcon, SwitcherLanguageIcon,
    UserDefaultIcon } from '../common/Svg'
import Sidebar from './SideBar'
import { getLanguageList, getMenuItems, getUser, getWhiteLabelingList } from '../../state/user/selectors'
import ProgressDropDown from '../../pages/progress/ProgressDropDown'
import { Context } from '../common/BaseContext'
import { readableC02 } from '../../utils/helpers'
import { IMenuItems, IMenuItemsData, IMonthlyData, IUser } from '../../types/data'
import { getPrizeDraw } from '../../state/prize/selectors'
import { travelSelector } from '../../state/travel/selectors'
import AccountMenuContent from '../AccountMenuContent'
import LanguageModal from '../LanguageModal'

export const integrateMenuAndNavigation = (menuItems: IMenuItemsData[], user: IUser, prizeDraw: IMonthlyData, learnUrls?: any, lowerUrls?: any, meUrls?: any, gameList?: any) => {
    const navigationMenuItems = [
        { id: 1, name: 'Home', href: '/', items: [] },
        { id: 2, name: 'Plan', href: '/plan/plan-journey', mainHref: '/plan', items: [] },
        { id: 3, name: 'Log', href: ((gameList?.filter((i: any) => !i?.isDisabled)?.length === 1 && user?.company?.autoForwardIfSingleEvent) && user?.company?.travelTrackingType !== 'standard') ? '/log' : '/log/track-travel-fan', mainHref: '/log', items: [] },
        {
            id: 4,
            name: 'Learn',
            href: learnUrls,
            mainHref: '/learn',
            icon: <LearnIcon />,
            items: [
                { name: 'Organisation News', href: '/learn/organisation-news' },
                { name: 'Articles', href: '/learn/articles' },
            ],
        },
        {
            id: 5,
            name: 'Lower',
            href: lowerUrls,
            mainHref: '/lower',
            icon: <LowerIcon />,
            items: [
                { name: 'Travel', href: '/lower/travel-articles' },
                { name: 'Pledges', href: '/lower/pledges' },
                { name: 'Swaps', href: '/lower/swaps' },
                { name: 'Recipes', href: '/lower/recipes' },
            ],
        },
        {
            id: 6,
            name: 'Me',
            href: meUrls,
            mainHref: '/me',
            icon: <MeIcon />,
            items: [
                user?.company?.gamification ? { name: 'Progress', href: '/me/progress' } : {},
                user?.company?.gamification ? { name: 'Earn points', href: '/me/earn-points' } : {},
                { name: 'Impact', href: '/me/impact' },
                user?.company?.gamification && prizeDraw?.showLeaderboard ? { name: 'Leaderboard', href: '/me/leaderboard' } : {},
                user?.company?.gamification ? { name: 'Achievements', href: '/me/achievements' } : {},
            ],
        },
    ]

    const predefinedOrder = [
        { name: 'Home', position: 1 },
        { name: 'Plan', position: 2 },
        { name: 'Log', position: 3 },

        {
            name: 'Learn',
            position: 4,
            items: [
                { name: 'Organisation News', position: 1 },
                { name: 'Articles', position: 2 },
            ],
        },
        {
            name: 'Lower',
            position: 5,
            items: [
                { name: 'Travel', position: 1 },
                { name: 'Pledges', position: 2 },
                { name: 'Swaps', position: 3 },
                { name: 'Recipes', position: 4 },
            ],
        },
        {
            name: 'Me',
            position: 6,
            items: [
                user?.company?.gamification ? { name: 'Progress', position: 1 } : {},
                user?.company?.gamification ? { name: 'Earn points', position: 2 } : {},
                { name: 'Impact', position: 3 },
                user?.company?.gamification && prizeDraw?.showLeaderboard ? { name: 'Leaderboard', position: 4 } : {},
                user?.company?.gamification ? { name: 'Achievements', position: 5 } : {},
            ],
        },
    ]

    const navigationData = menuItems?.map((menuItem: IMenuItemsData) => {
        const correspondingNavItem = navigationMenuItems.find((navItem) => navItem.name === menuItem.name)
        const predefinedItem = predefinedOrder.find((item) => item.name === menuItem.name)

        return correspondingNavItem && predefinedItem && {
            id: correspondingNavItem.id,
            name: correspondingNavItem.name,
            href: correspondingNavItem.href,
            mainHref: correspondingNavItem.mainHref,
            icon: correspondingNavItem.icon,
            position: predefinedItem.position,
            items: menuItem.items?.map((subItem: IMenuItems) => {
                const correspondingSubNavItem = correspondingNavItem?.items?.find((subNavItem) => subNavItem?.name === subItem?.name)
                const subItemPosition = predefinedItem.items?.find((item) => item.name === subItem.name)?.position || 0
                return correspondingSubNavItem && {
                    name: correspondingSubNavItem?.name,
                    href: correspondingSubNavItem?.href,
                    position: subItemPosition,
                }
                // @ts-ignore
            }).filter(Boolean).sort((a, b) => a.position - b.position) || [],
        }
        // @ts-ignore
    }).filter(Boolean).sort((a, b) => a.position - b.position)

    navigationData?.sort((a, b) => {
        const positionA = predefinedOrder.find((item) => item.name === a?.name)?.position || 0
        const positionB = predefinedOrder.find((item) => item.name === b?.name)?.position || 0
        return positionA - positionB
    })

    return navigationData
}

interface IProps {
    spaceOnBottom?: boolean
}

export default function Navbar({ spaceOnBottom = true }: IProps): JSX.Element {
    const location = useLocation()
    const navigate = useNavigate()
    const { screenWidth } = useContext(Context)
    const { languageItem } = useSelector(getLanguageList)
    const { user, token } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { navbarHeight } = useContext(Context)
    const [openedDropdown, setOpenedDropdown] = useState(0)
    const { menuItems } = useSelector(getMenuItems)
    const { pathname } = window.location
    const { prizeDraw } = useSelector(getPrizeDraw)
    const { gameList } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [languageShowModal, setLanguageModal] = useState(false)

    const { learnUrls, lowerUrls, meUrls } = useContext(Context)
    const navigationData = integrateMenuAndNavigation(menuItems, user, prizeDraw, learnUrls, lowerUrls, meUrls, gameList)

    const navLinkStyle = ({ isActive }: any) => ((
        isActive ? styles.activeText : styles.defaultText
    ))

    useEffect(() => {
        if (!token || !token.token) {
            navigate(`/auth?to=${pathname}${window.location.search.replace('?', '&')}`)
            return
        }

        const activeTopNavigation = document.getElementById('active_top_navigation')
        const topContainer = document.getElementById('top_navigation_container')
        const topNavigationIcon = document.getElementById('top_navigation_icon')

        if (activeTopNavigation && topContainer && topNavigationIcon) {
            topContainer.scrollTo({ left: activeTopNavigation.offsetLeft - topNavigationIcon.offsetWidth })
        }

        // eslint-disable-next-line
    }, [])

    const activeNavigation = navigationData?.filter((i) => (i?.mainHref ? pathname.startsWith(i?.mainHref) : false))[0]
    const activeNavigationItems = activeNavigation?.items

    return (
        <div className="w-full">
            <div style={{ zIndex: 1000 }} className="fixed w-full laptop:m-auto z-50 medium:border-b-2 border-[#c8c8c8] bg-spinach" id="navbar">
                <div className={`${(user?.company?.menuPosition === 'Bottom' || user?.company?.menuPosition === 'None') && 'hidden'} flex justify-center`}>
                    <Disclosure as="nav" className="bg-spinach w-[1600px] medium:h-[88px] h-[64px] laptop:max-w-[1600px]">
                        <div className="mx-auto px-4 medium:px-10 h-full">
                            <div className="relative flex h-full items-center justify-between">
                                <div className="flex items-center justify-center">
                                    <div style={{ width: user?.company?.logoContainerWidth }} className="flex flex-shrink-0 items-center">
                                        <NavLink to="/">
                                            {/* {loading && <Skeleton style={{ width: user?.company?.logoContainerWidth, height: user?.company?.logoHeight }} className="rounded" />} */}

                                            <img
                                                style={user?.company?.logo === null ? { width: 95, height: 28 } : { width: user?.company?.logoWidth, height: user?.company?.logoHeight }}
                                                className="nav-logo object-contain"
                                                src={user?.company?.logo === null ? logoWhite : user?.company?.logo}
                                                alt="Your Company" />
                                        </NavLink>
                                    </div>

                                    <div className="absolute bottom-0 hidden medium:block" style={{ left: 16 + (user?.company?.logoContainerWidth || 0) }}>
                                        <div className="hidden medium:block ml-[28px]">
                                            <div className="flex">
                                                {navigationData?.map((item: any, index: number) => (
                                                    <div key={item?.id || index} className="flex space-between">
                                                        {item.items.length === 0 ? (
                                                            <div className={cn('pb-[30px] mb-[-0.5px] px-3', { 'border-b-[4px] border-pistachio': pathname === item.href })}>
                                                                <NavLink
                                                                    state={{ prevUrl: location?.pathname }}
                                                                    to={item.href}
                                                                    style={navLinkStyle}>
                                                                    <span className="text-savoy max-medium:hidden font-[400px] hover:font-bold">
                                                                        {t(`navbar.${item.name}`)}
                                                                    </span>
                                                                </NavLink>
                                                            </div>
                                                        ) : (
                                                            <div className={cn('pb-[30px] px-3 mb-[-0.5px]', { 'border-b-[4px] border-pistachio': pathname.startsWith(item.mainHref || '') })} onMouseEnter={() => setOpenedDropdown(item.id)} onMouseLeave={() => setOpenedDropdown(0)}>
                                                                <button id="dropdownDefaultButton"
                                                                    className="text-savoy text-center inline-flex items-center"
                                                                    style={styles.defaultText}
                                                                    onClick={() => navigate(item.href)}
                                                                    type="button">
                                                                    {t(`navbar.${item.name}`)}
                                                                    <svg
                                                                        className="w-2.5 h-2.5 ml-2" aria-hidden="true"
                                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                        viewBox="0 0 10 6">
                                                                        <path stroke="currentColor" strokeLinecap="round"
                                                                            strokeLinejoin="round" strokeWidth="2"
                                                                            d="m1 1 4 4 4-4" />
                                                                    </svg>
                                                                </button>

                                                                <div style={{ borderTopWidth: 1.5, borderTopColor: '#c8c8c8' }} id="dropdown" className={cn('z-10 bg-white divide-y divide-gray-100 shadow w-25 absolute', { hidden: openedDropdown !== item.id, 'mt-[33.9px]': window.location.pathname !== '/', 'mt-[30px]': !pathname.startsWith(item.mainHref || ''), 'mt-[34px]': pathname.startsWith(item.mainHref || '') })}>
                                                                    <ul className="text-sm text-gray-700 dark:text-gray-200"
                                                                        aria-labelledby="dropdownDefaultButton">
                                                                        {item.items.map((subItem: any, subItemIndex: number) => (
                                                                            <li onClick={() => navigate(subItem.href)} key={subItem?.id || subItemIndex}>
                                                                                <p
                                                                                    style={{ boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}
                                                                                    className={` ${pathname.startsWith(subItem.href) && 'text-savoy bg-spinach'} mb-0.5 block cursor-pointer px-5 text-14 py-2 pr-7 hover:bg-spinach bg-mint hover:text-pistachio text-marrow shadow-md`}>
                                                                                    {t(`navbar.${subItem?.name}`)}
                                                                                </p>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="inset-y-0 right-0 flex items-end static">
                                    {/* Profile dropdown */}
                                    {user?.company?.gamification ? (
                                        <Menu as="div" className="relative inline-block text-left">
                                            <Menu.Button className="max-medium:hidden">
                                                {prizeDraw?.showProgressBar && (
                                                    <div className="flex items-end max-medium:hidden">
                                                        <div className="flex items-center ml-4 max-s-mobile:hidden">
                                                            <div>
                                                                <LeafIcon width={16} height={16} color={whiteLabeling?.savoy} />
                                                            </div>

                                                            <span className="text-15 font-medium not-heading-font tracking-tight text-savoy ml-1.5 mr-3.5 max-tablet:hidden font">
                                                                {user?.thisMonthPoints} {t('global.pts')}
                                                            </span>
                                                        </div>

                                                        <div className="flex items-center ml-4 max-s-mobile:hidden">
                                                            <div>
                                                                <FlagIcon width={16} height={16} color={whiteLabeling?.savoy} />
                                                            </div>

                                                            <span
                                                                className="text-15 font-medium not-heading-font tracking-tight text-savoy ml-1.5 mr-3.5 max-tablet:hidden font">
                                                                #{user?.rank === null ? t('navbar.top') : user?.rank}
                                                            </span>
                                                        </div>

                                                        <div className="flex items-center ml-4 max-s-mobile:hidden">
                                                            <div>
                                                                <ArrowBottomIcon color={whiteLabeling?.savoy} width={16} height={16} />
                                                            </div>

                                                            <span className="text-15 font-medium not-heading-font not-heading-font tracking-tight text-savoy ml-1.5 mr-4 max-tablet:hidden font">
                                                                {readableC02(user?.thisMonthCeoKg || 0)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Button>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-in-out duration-300"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in-out duration-300"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items
                                                    className="z-50 w-[390px] absolute right-0 mt-2 mr-3 origin-top-right bg-white rounded-md"
                                                    style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.4)' }}>
                                                    <div>
                                                        <div
                                                            className="overflow-y-scroll scrollbar-hide max-h-[519px]">
                                                            <ProgressDropDown />
                                                        </div>

                                                        <div
                                                            className="border-t border-t-solid border-avocado flex items-center justify-center w-full h-[80px] bg-white rounded-b-md">
                                                            <button onClick={() => navigate('/me/progress')}
                                                                className="border-2 not-heading-font border-solid border-rocket bg-mint hover:text-spinach font-semibold py-[12px] px-[28px] rounded-md text-14 text-marrow">
                                                                {t('navbar.view-all-progress')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    ) : null}

                                    <Menu as="div" className="relative ml-6 tablet:ml-2 transition">
                                        <div className="flex items-center">
                                            {languageItem?.length > 1 && (
                                                <div className="mr-2.5 cursor-pointer mt-1" onClick={() => setLanguageModal(true)}>
                                                    <SwitcherLanguageIcon />
                                                </div>
                                            )}

                                            {!user?.company?.anonymousMode && (
                                                <div>
                                                    <Menu.Button
                                                        className="flex rounded-full mb-[-5px]">
                                                        <span className="sr-only">Open user menu</span>
                                                        {/* eslint-disable-next-line no-nested-ternary */}
                                                        {!user.uploadedImage && !user.socialImage ? (
                                                            <div className="medium:w-10 medium:h-10 w-[36px] h-[36px]">
                                                                <UserDefaultIcon
                                                                    className="medium:w-10 medium:h-10 w-[36px] h-[36px] mb-0" />
                                                            </div>
                                                        ) : user.uploadedImage ? (
                                                            <img
                                                                className="rounded-full medium:w-10 medium:h-10 w-[36px] h-[36px] object-cover"
                                                                src={user.uploadedImage}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <img
                                                                className="rounded-full medium:w-10 medium:h-10 w-[36px] h-[36px] object-cover"
                                                                src={user.socialImage}
                                                                alt=""
                                                            />
                                                        )}
                                                    </Menu.Button>
                                                </div>
                                            )}
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-in-out duration-300"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in-out duration-300"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Menu.Items
                                                className="scrollbar-hide z-[100000] absolute right-0 mt-2 origin-top-right rounded-md bg-white py-7 px-6 ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[90vh] overflow-y-scroll"
                                                style={{
                                                    width: screenWidth < 420 ? screenWidth : 390,
                                                    right: screenWidth < 420 ? -65 : 0,
                                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.4)',
                                                }}>
                                                <AccountMenuContent />
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>

                                    <Sidebar navigation={navigationData} />
                                </div>
                            </div>
                        </div>
                    </Disclosure>
                </div>

                {/* <LayoutProgressBar /> */}

                {activeNavigation && activeNavigation.items.length > 0 ? (
                    <div className="flex medium:hidden bg-pistachio items-center">
                        <div id="top_navigation_icon">
                            <div onClick={() => navigate(activeNavigation.href)} className="mx-[27px] h-10 cursor-pointer flex mobile:justify-center max-mobile:flex-col items-center xs-max:w-[110px] w-[56px]">
                                {activeNavigation.icon}
                                <p className="not-heading-font text-marrow font-medium text-14 leading-4 mobile:ml-[10px]">
                                    {t(`navbar.${activeNavigation?.name}`)}
                                </p>
                            </div>
                        </div>

                        <div className="flex w-full h-[48px] items-center overflow-x-auto no-scrollbar" id="top_navigation_container">
                            <div className="flex w-full">
                                {activeNavigationItems?.map((item) => {
                                    const isActive = pathname.startsWith(item?.href || '')
                                    return (
                                        <div key={item?.name}
                                            id={isActive ? 'active_top_navigation' : undefined}
                                            style={{ width: `${100 / activeNavigationItems.length}%`, minWidth: screenWidth < 625 ? 120 : 175 }}>
                                            <div
                                                onClick={() => navigate(item?.href || '')}
                                                className={cn('justify-center text-marrow cursor-pointer flex h-[48px] items-center border-b-4', {
                                                    'border-spinach': isActive,
                                                    'border-pistachio': !isActive,
                                                })}>
                                                {t(`navbar.${item?.name}`)}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {activeNavigation?.name === 'Me' && (
                            user?.company?.menuPosition !== 'Top' && (
                                <div className="bg-pistachio w-[48px]" style={{ right: 0 }}>
                                    <div onClick={() => navigate('/me/account-settings')}
                                        className="flex h-[48px] w-[48px] cursor-pointer items-center justify-center">
                                        <SettingsIcon />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                ) : null}
            </div>

            {spaceOnBottom && <div style={{ marginBottom: navbarHeight }} />}

            <LanguageModal onClose={() => setLanguageModal(false)} isShown={languageShowModal} />
        </div>
    )
}

const styles = {
    defaultText: {
        display: 'flex',
        fontSize: 14,
        letterSpacing: 0.45,
    },
    activeText: {
        display: 'flex',
        fontSize: 14,
        fontWeight: 700,
        letterSpacing: 0.45,
    },
}
